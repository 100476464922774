var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ct-table" },
    [
      _vm.loaded
        ? _c(
            "b-table",
            _vm._g(
              _vm._b(
                {
                  ref: "btable",
                  attrs: {
                    "show-empty": "",
                    "selected-variant": "none",
                    "tbody-class": _vm.tbodyClass,
                    "thead-class":
                      _vm.theadClass + (_vm.hideHeaders ? " d-none" : ""),
                    "sticky-header": _vm.stickyHeader,
                    small: _vm.smallState,
                    stacked: _vm.stackedState,
                    responsive: true,
                    fields: _vm.tableDefinition,
                    items: _vm.items,
                    "per-page": _vm.totalRecords ? 0 : _vm.resultsPerPage,
                    "current-page": _vm.currentPage,
                    selectable: _vm.selectable,
                    "select-mode": _vm.selectMode,
                    "no-local-sorting": _vm.noLocalSorting,
                  },
                  on: {
                    "row-selected": _vm.onRowSelected,
                    "head-clicked": _vm.onHeadClicked,
                    "row-clicked": _vm.onRowClicked,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "head(selected)",
                        fn: function (data) {
                          return [
                            _vm.showSelectAllCheckbox
                              ? _c("b-form-checkbox", {
                                  attrs: {
                                    "cy-data": `select-${data.index}`,
                                    indeterminate: _vm.isSelectAllIndeterminate,
                                  },
                                  on: {
                                    "update:indeterminate": function ($event) {
                                      _vm.isSelectAllIndeterminate = $event
                                    },
                                    input: function ($event) {
                                      return _vm.handleSelectAllCheckbox(
                                        $event,
                                        data.index
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.isSelectAllChecked,
                                    callback: function ($$v) {
                                      _vm.isSelectAllChecked = $$v
                                    },
                                    expression: "isSelectAllChecked",
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "cell(selected)",
                        fn: function (data) {
                          return [
                            _vm.selectableFunction(data.item)
                              ? _c("b-form-checkbox", {
                                  on: {
                                    input: function ($event) {
                                      return _vm.handleRowCheckbox(
                                        $event,
                                        data.index
                                      )
                                    },
                                  },
                                  model: {
                                    value: data.rowSelected,
                                    callback: function ($$v) {
                                      _vm.$set(data, "rowSelected", $$v)
                                    },
                                    expression: "data.rowSelected",
                                  },
                                })
                              : _c(
                                  "div",
                                  [
                                    _vm._t(
                                      "selectable-alt-content",
                                      null,
                                      null,
                                      data
                                    ),
                                  ],
                                  2
                                ),
                          ]
                        },
                      },
                      {
                        key: "row-details",
                        fn: function (slotData) {
                          return [
                            _c(
                              "div",
                              {
                                ref: "showDetails",
                                class: _vm.showDetailsClasses,
                              },
                              [
                                _vm._t(
                                  "expanded-row-details",
                                  null,
                                  null,
                                  slotData
                                ),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                      _vm._l(_vm.$scopedSlots, function (_, name, index) {
                        return {
                          key: name,
                          fn: function (slotData) {
                            return [
                              _c(
                                "div",
                                { key: index, staticClass: "expandable-row" },
                                [
                                  _vm.expandable &&
                                  [0, 1].includes(index) &&
                                  _vm.expandableFunction(slotData.item)
                                    ? _c("div", { staticClass: "caret" }, [
                                        _c("span"),
                                      ])
                                    : _vm._e(),
                                  _vm._t(name, null, null, slotData),
                                ],
                                2
                              ),
                            ]
                          },
                        }
                      }),
                    ],
                    null,
                    true
                  ),
                },
                "b-table",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            )
          )
        : _vm._e(),
      _vm.isPaginated &&
      _vm.loaded &&
      _vm.items &&
      (_vm.currentPage > 0 || _vm.items.length > _vm.perPage - 1)
        ? _c(
            "b-row",
            { staticClass: "paginator" },
            [
              _c("c-pagination", {
                attrs: {
                  items: _vm.items,
                  "current-page": _vm.currentPage,
                  "per-page": _vm.resultsPerPage,
                  "total-records": _vm.totalRecords,
                },
                on: {
                  "update:currentPage": function ($event) {
                    _vm.currentPage = $event
                  },
                  "update:current-page": function ($event) {
                    _vm.currentPage = $event
                  },
                },
              }),
              _c(
                "b-form-group",
                [
                  _vm.items && _vm.items.length > 0
                    ? _c("b-form-select", {
                        staticClass: "my-0",
                        attrs: {
                          id: "perPageSelect",
                          size: "sm",
                          align: "fill",
                          options: _vm.pageOptions,
                        },
                        model: {
                          value: _vm.resultsPerPage,
                          callback: function ($$v) {
                            _vm.resultsPerPage = $$v
                          },
                          expression: "resultsPerPage",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.loaded ? _c("ct-centered-spinner") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }